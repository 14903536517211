const msg = 'Campo é obrigatório.'

export default {
  errorGoalId () {
    const errors = []
    if (!this.$v.item.goalId.$dirty) return errors
    !this.$v.item.goalId.required && errors.push(msg)
    return errors
  },
  errorSalesGoalValue () {
    const errors = []
    if (!this.$v.item.salesGoalValue.$dirty) return errors
    !this.$v.item.salesGoalValue.required && errors.push(msg)
    return errors
  },
  errorDebitGoalValue () {
    const errors = []
    if (!this.$v.item.debitGoalValue.$dirty) return errors
    !this.$v.item.debitGoalValue.required && errors.push(msg)
    return errors
  },
  errorResellersQuantityGoal () {
    const errors = []
    if (!this.$v.item.resellersQuantityGoal.$dirty) return errors
    !this.$v.item.resellersQuantityGoal.required && errors.push(msg)
    return errors
  },
  errorEntities () {
    const errors = []
    if (!this.$v.item.entityId.$dirty) return errors
    !this.$v.item.entityId.required && errors.push(msg)
    return errors
  }
}

