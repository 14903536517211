<template>
  <Modal
    :activeModal="activeModal"
    :widthModal="450"
    @closeModal="closeModal">
   <template v-slot:btnActivator>
      <v-btn
        tile
        v-if="permiteAcao($route, 'add')" 
        :color="variables.colorPrimary"
        dark
        class="mb-2 modal-cadastro-btn-cadastrar"
        @click="openModal"
        >Novo cadastro</v-btn>
    </template>
    <v-card>
      <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
          {{ isEdit ? 'Editar Meta' : 'Cadastrar Meta Promotor' }}
          <v-spacer></v-spacer>
          <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>
      
      <v-form>
        <!-- <v-card-title class="modal-cadastro-titulo">
          
        </v-card-title> -->

          <v-card-text class="modal-cadastro-form">
          <v-row>
          
         <!--    <v-col cols="12" class="modal-cadastro-form-col-imput mt-3">
              <v-menu
                v-model="menuDateInicial"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px">

                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="dtInicialString"
                      :label="$gettext('Data Referência')"
                      v-mask="['##/##/####']"
                      @input="testeDate(dtInicialString)"
                      v-on="on"
                      dense>
                      <template v-slot:append>
                        <v-icon v-on="on">
                          event
                        </v-icon>
                      </template>
                    </v-text-field>
                  </template>

                <v-date-picker
                  v-model="item.refDate"
                  no-title
                  scrollable
                  locale="pt-br"
                  :type="'date'"
                  @input="closeModalDatePicker(item.refDate)"
                  :color="variables.colorPrimary">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="clearDateFilter(item.refDate)">Limpar</v-btn>
                    <v-btn text color="primary"  @click="closeModalDatePicker(item.refDate)">OK</v-btn>
                </v-date-picker>
              </v-menu>
            </v-col> -->

            <v-col cols="12" class="modal-cadastro-form-col-imput pb-0">
              <v-select  :error-messages="errorGoalId" v-model="item.goalId" :items="listMetas" label="Meta de Referência"></v-select>
            </v-col>
            <v-col dense cols="12" md="12" class=" pb-0 pt-0">
                <autoComplete :error-messages="errorEntities" :entityProps.sync="item.entityId" :label="'Promotores'" :typesEntities="['3']"  />
            </v-col>

            <v-col cols="12" class="modal-cadastro-form-col-imput">
              <v-currency-field :error-messages="errorSalesGoalValue" v-model="item.salesGoalValue" label="Meta de Vendas"  :prefix="'R$'"   @input="$v.item.salesGoalValue.$touch()" @blur="$v.item.salesGoalValue.$touch()"  />
            </v-col>

            <v-col cols="12" class="modal-cadastro-form-col-imput">
              <v-currency-field :error-messages="errorDebitGoalValue" v-model="item.debitGoalValue" label="Meta de Débito"  suffix="%"   @input="$v.item.debitGoalValue.$touch()" @blur="$v.item.debitGoalValue.$touch()"  />
            </v-col>

            <v-col cols="12" class="modal-cadastro-form-col-imput">
              <v-text-field :error-messages="errorResellersQuantityGoal" type="number" v-model="item.resellersQuantityGoal" label="Qtd. Revendedores" @input="$v.item.resellersQuantityGoal.$touch()" @blur="$v.item.resellersQuantityGoal.$touch()" />
            </v-col>

            <!-- <v-col cols="12" class="modal-cadastro-form-col-imput">
              <v-currency-field v-model="item.minimumResellerSalesValue"  label="Minimo Vendas" :prefix="'R$'"  class=" text-left" @input="$v.item.minimumResellerSalesValue.$touch()" @blur="$v.item.minimumResellerSalesValue.$touch()" />
            </v-col> -->

           <!--  <v-col cols="12" class="modal-cadastro-form-col-imput">
              <v-select
                v-model="item.chipContractId"
                :items="listContracts"
                label="Contrato"></v-select>
            </v-col> -->

          </v-row>
        </v-card-text>

        <v-card-actions class="modal-cadastro-footer">
          <v-btn :color="variables.colorPrimary" @click="closeModal" class="br-btn br-btn-cancelar">Cancelar</v-btn>
          <v-btn :color="variables.colorPrimary" @click.prevent="submit" class="br-btn" :loading="loading">{{ isEdit ? 'Salvar' : 'Cadastrar' }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </Modal>
</template> 

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { mask } from 'vue-the-mask'
import Events from '@/core/service/events'
import variables from '@/assets/styles/helpers/_variables.scss'
import moment from 'moment'
import validacao from './validacao'
import { map } from 'lodash'
export default {
  name: 'BrModalCadastroMetas',
  mixins: [validationMixin],
  directives: {mask},
  components: {
    Modal: () => import('@/views/components/modal'),
    autoComplete: () => import('@/views/components/autoCompleteEntities')
  },
  data: () => ({
    activeModal: false,
    loading: false,
    entity: {},
    dtInicialString: moment().format('DD/MM/YYYY'),
    menuDateInicial: false
  }),
  watch: {
    'item.refDate' (v) {
      this.dtInicialString = moment(v, 'YYYY-MM-DD').format('DD/MM/YYYY')
    }
  },
  computed: {
    ...mapGetters('listarMetas', ['listaItens']),
    ...mapGetters('metasEntidades', ['item']),
    ...mapGetters('roles', ['permiteAcao']),
    ...validacao,
    variables: () => variables,
    listMetas () {
      let dados = this.listaItens
      return map(dados, v => ({ text: moment(v.refDate).format('DD/MM/YYYY'), value: v.id }) )
    },
    isEdit () {
      return this.item.id ? !!1 : !!0
    }
  },
  mounted () {
    // this.obterItensSelect()
    this.getMetas()
    Events.$on('cadastro::openModalCadastro', () => {
      this.openModal()
    })
  },
  methods: {
    ...mapActions('listarMetas', {'getMetas': 'getItens'}),
    ...mapActions('metasEntidades', ['cadastrarItem', 'limparItem', 'getItens', 'editarItem']),
    testeDate (dtString) {
      this.item.refDate = moment(dtString, 'DD/MM/YYYY').format('YYYY-MM-DD')
    },
    closeModalDatePicker (date) {
      this.dtInicialString = moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
      this.menuDateInicial = false
    },
    openModal () {
      this.activeModal = true
    },

    closeModal () {
      this.activeModal = false
      this.limparItem()
      this.$v.item.$reset()
    },
    editItem (item) {
      this.getItem(item).then(() => {
        Events.$emit('cadastro::openModalCadastro', item)
      }).catch(() => {
        this.msgAll('Error ao selecionar o item', true)
      })
      // this.dialog = true
    },
    submit () {
      const dados = this.item
      if (this.$v.item.$invalid) {
        this.$v.item.$touch()
        return false 
      }


      if (typeof dados.entityId === 'number') {
        // eslint-disable-next-line no-self-assign
        dados.entityId = dados.entityId
      } else {
        dados.entityId = dados.entityId.value
      }

      this.loading = true
     if (this.item.id) {
       dados.id = this.item.id
        this.editarItem(dados).then(() => {
          this.getItens({ page: 1, pageSize: this.$store.getters.ItensPaginacao })
          this.closeModal()
          this.loading = false
          const msg = {
            toggle: true,
            type: 'success',
            msg: 'Edição realizado com sucesso!'
          }
          Events.$emit('snackbarCadastro::msg', msg)
        }).catch(err => {
          this.loading = false
          const msg = {
            toggle: true,
            type: 'error',
            msg: err.msg || 'Edição não realizado!'
          }
          Events.$emit('snackbarCadastro::msg', msg)
        })
      } else {
        this.cadastrarItem(dados).then(() => {
          this.getItens({ page: 1, pageSize: this.$store.getters.ItensPaginacao })
          this.closeModal()
          this.loading = false
          const msg = {
            toggle: true,
            type: 'success',
            msg: 'Cadastro realizado com sucesso!'
          }
          Events.$emit('snackbarCadastro::msg', msg)
        }).catch(err => {
          this.loading = false
          const msg = {
            toggle: true,
            type: 'error',
            msg: err.error || 'Cadastro não realizado!'
          }
          Events.$emit('snackbarCadastro::msg', msg)
        })
      }
    }
  },
  validations () {
    return  {
      item: {
        entityId: { required },
        goalId: { required },
        salesGoalValue: { required },
        debitGoalValue: { required },
        resellersQuantityGoal: { required },
      }
    }
  }
}
</script>

<style lang="scss">
  @import './../../../../../assets/styles/components/formularios';
  @import './../../../../../assets/styles/components/modal-cadastro';
  .v-text-field--outlined fieldset {
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-bottom: 1px solid;
    border-radius: 0;
  }
  .v-text-field--outlined.v-input--is-focused fieldset{
    border: 0;
    border-bottom:2px solid #1cb4ff;
  }
</style>